import * as React from 'react';
import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import getConfig from 'next/config';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
      new RewriteFrames({
        iteratee: frame => {
          frame.filename = frame?.filename?.replace(distDir, 'app:///_next');
          return frame;
        },
      }),
      new Integrations.BrowserTracing(),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    ignoreErrors: [
      'top.GLOBALS',
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      'fb_xd_fragment',
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      'conduitPage',
    ],
    // TODO: Lower before production
    tracesSampleRate: 1.0,
  });
  Sentry.setTag('ssr', typeof window === 'undefined' ? 'true' : 'false');
}

interface SentryExtra {
  query?: any;
  asPath?: string;
  pageProps?: any;
}

interface UseSentryOptions {
  extras?: SentryExtra;
}

export const useSentryScope = (options?: UseSentryOptions) => {
  React.useEffect(() => {
    Sentry.configureScope(scope => {
      if (localStorage != null) {
        const user = localStorage.getItem('user');
        if (user != null) {
          try {
            scope.setUser(JSON.parse(user));
            /* eslint-disable-next-line */
          } catch (e) {}
        }
      }
      if (options?.extras != null) {
        scope.setExtras(options?.extras as any);
      }
    });
  });
};
