import * as React from 'react';
import { from, ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import fetch from 'isomorphic-unfetch';
import { SentryLink } from 'apollo-link-sentry';
// import {
//   UserType,
// } from '@sheet-best/core/lib/graphql/hooks';
import { GetServerSidePropsContext } from 'next';
import { AppTreeType } from 'next/dist/next-server/lib/utils';
import { relayStylePagination } from '@apollo/client/utilities';

const isSSR = typeof window === 'undefined';

export interface NextApolloContext {
  /**
   * `Component` the tree of the App to use if needing to render separately
   */
  AppTree: AppTreeType;
  apolloClient: ApolloClient<NormalizedCacheObject>;
  // me?: UserType;
}

export type NextApolloServerSideContext = GetServerSidePropsContext & NextApolloContext;

let apolloClient: ApolloClient<any> | null = null;

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 *
 * @param {Object} initialState Object representing the initial state of data
 * @param {Object} cookie Optional request cookies
 * @returns {ApolloClient} Apollo's client
 */
export const initializeApollo = (initialState?: any, cookie?: string): ApolloClient<any> => {

  const internalClient = apolloClient ?? createApolloClient(cookie);
  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = internalClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    internalClient.cache.restore({ ...existingCache, ...initialState });
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return internalClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = internalClient;

  return internalClient;
};

/**
 * Creates and configures the ApolloClient
 *
 * @param {Object} cookie Optional request cookies
 * @returns {ApolloClient} Apollo's client
 */
const createApolloClient = (cookie?: string) => {
  const enhancedFetch = (url: string, init?: RequestInit) => {
    if (process.env.NODE_ENV !== 'production' && init != null) {
      logRequest(init);
    }
    return fetch(url, {
      ...init,
      headers: {
        ...init?.headers,
        ...(cookie ? { cookie: cookie } : {}),
      },
      credentials: 'include',
    }).then((res: Response) => res);
  };
  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  return new ApolloClient({
    // Disables forceFetch on the server (so queries are only run once)
    ssrMode: isSSR,
    link: from([
      new SentryLink({ breadcrumb: { includeVariables: true, includeError: true } }),
      createUploadLink({
        uri: isSSR
          ? process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT_LOCAL
          : process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
        credentials: 'include',
        fetch: enhancedFetch,
      }) as any,
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            connectionHits: relayStylePagination(['orderby', 'after', 'first', 'connection']),
            connections: relayStylePagination(['orderby', 'search', 'starred']),
          },
        },
      },
    }),
  });
};

export const useApollo = (initialState: any) => {
  const store = React.useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
};

const logRequest = (init: RequestInit) => {
  let body;
  if (typeof init.body === 'string') {
    try {
      body = JSON.parse(init.body);
    } catch (e) {
      console.error(e);
    }
  }
  if (typeof window !== 'undefined' && init.body instanceof FormData) {
    const ops = init.body.get('operations');
    // body = init.body.
    if (ops != null && typeof ops === 'string') {
      try {
        body = JSON.parse(ops);
      } catch (e) {
        console.error(e);
      }
    }
  }
  console.log(
    '========================================================================',
    `\nGRAPHQL: Called "${body?.operationName}" with`,
    body?.variables,
    '\n========================================================================'
  );
};
