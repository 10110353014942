import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#207245',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#f44336',
      contrastText: '#FFF',
    },
    text: {
      // Minimum contrast ration o 7:1. It passes the AAA test for small font-size
      primary: 'rgba(40, 40, 40, 1)',
      secondary: 'rgba(0, 0, 0, 0.68)',
    },
  },
});
