import * as React from 'react';
import { AppProps } from 'next/app';
import { theme } from '@app/util/theme';
import { ThemeProvider, StylesProvider } from '@material-ui/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { CssBaseline } from '@material-ui/core';
import { NProgressStyleCreator, usePageLoader } from '@0soft/use-nextjs-page-loader';
import { DefaultSEO } from '@app/components/default-seo';
import { GlobalSnackbar } from '@0soft/zero-material-ui/dist/feedback/global-snackbar';
import * as Sentry from '@sentry/react';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '@app/util/apollo';
import { useSentryScope } from '@app/util/sentry';

const NProgressStyle = NProgressStyleCreator('rgba(0, 91, 168, 1)');

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

const gtagConversion = {
  __html: `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'AW-479594313');
  gtag('config', 'UA-115670045-4');
`,
};

const CustomApp: React.FC<AppProps> = ({ Component, pageProps, router }) => {
  const apolloClient = useApollo(pageProps.initialApolloState);
  useSentryScope();
  usePageLoader();
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles != null) {
      jssStyles.parentElement!.removeChild(jssStyles);
    }
  });
  return (
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <DefaultSEO />
          <CssBaseline />
          <StyledThemeProvider theme={theme}>
            <Component {...pageProps} key={router.route} />
            <GlobalSnackbar />
            <NProgressStyle />
          </StyledThemeProvider>
        </StylesProvider>
      </ThemeProvider>
      {process.env.NODE_ENV === 'production' && (
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-479594313"></script>
      )}
      {process.env.NODE_ENV === 'production' && (
        <script dangerouslySetInnerHTML={gtagConversion} />
      )}
    </ApolloProvider>
  );
};

export default Sentry.withProfiler(CustomApp);
